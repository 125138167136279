import axios from 'axios'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {FC, useState} from 'react'
import * as Yup from 'yup'
import {useNavigate} from 'react-router-dom'

const WithdrawCommission: FC = () => {
  const REACT_APP_AGENT_API = process.env.REACT_APP_AGENT_API
  const username = localStorage.getItem('username')
  const session = localStorage.getItem('session')
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const initialValues = {
    amount: '',
    destWalletAccNo: '',
    destWalletCd: '',
  }

  const filterSchema = Yup.object().shape({
    amount: Yup.number()
      .min(1, 'Minimum 1')
      .max(100000, 'Maximum 100000')
      .required('Please enter amount')
      .test('decimal-places', 'Only up to two decimal places allowed', (value: any) => {
        if (!value) return true // Allow empty value
        return /^(\d*\.\d{1,2}|\d+)$/.test(value)
      }),
    destWalletAccNo: Yup.number()
      .required('Please enter wallet account number')
      .integer('Please enter valid number'),
    destWalletCd: Yup.string().required('Please enter wallet code'),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: filterSchema,
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      setLoading(true)
      axios
        .post(
          `${REACT_APP_AGENT_API}/cash/comm/withdrawal/create`,
          {
            request: {
              username: username,
              session: session,
              amount: values.amount,
              transType: 'DIRECT', // DIRECT or CREDIT-CONVERSION
              destWalletAcc: values.destWalletAccNo,
              destWalletCd: values.destWalletCd,
            },
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then(function (response) {
          if (response.data.code == 200 && response.data.status == 'Success') {
            alert('Withdrawal request has been sent successfully')
            navigate(-1)
          } else {
            alert(response.data.message)
          }
          setLoading(false)
        })
        .catch(function (error) {
          alert(error)
          setLoading(false)
        })
    },
  })

  return (
    <div className='' style={{paddingTop: '10rem'}}>
      <form
        onSubmit={formik.handleSubmit}
        className='m-3 d-flex justify-content-center align-items-center'
      >
        <div className='row d-flex justify-content-around'>
          <h4 className=''>Enter Wallet Code</h4>
          <select
            {...formik.getFieldProps('destWalletCd')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.destWalletAccNo && formik.errors.destWalletAccNo,
              },
              {
                'is-valid': formik.touched.destWalletAccNo && !formik.errors.destWalletAccNo,
              }
            )}
            data-placeholder=''
          >
            <option value=''>Please Select Wallet Code</option>
            <option value='NAGAD'>NAGAD</option>
            <option value='BKASH'>BKASH</option>
            <option value='ROCKET'>ROCKET</option>
          </select>
          {formik.touched.destWalletCd && formik.errors.destWalletCd && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.destWalletCd}</span>
              </div>
            </div>
          )}
          <h4 className='mt-5'>Enter Wallet Account Number</h4>
          <input
            placeholder='Enter wallet account number'
            type='number'
            autoComplete='off'
            {...formik.getFieldProps('destWalletAccNo')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.destWalletAccNo && formik.errors.destWalletAccNo,
              },
              {
                'is-valid': formik.touched.destWalletAccNo && !formik.errors.destWalletAccNo,
              }
            )}
          />
          {formik.touched.destWalletAccNo && formik.errors.destWalletAccNo && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.destWalletAccNo}</span>
              </div>
            </div>
          )}
          <h4 className='mt-5'>Enter Amount</h4>
          <input
            placeholder='Enter amount'
            type='number'
            autoComplete='off'
            {...formik.getFieldProps('amount')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.amount && formik.errors.amount,
              },
              {
                'is-valid': formik.touched.amount && !formik.errors.amount,
              }
            )}
          />
          {formik.touched.amount && formik.errors.amount && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.amount}</span>
              </div>
            </div>
          )}

          <div className='col-4 p-1 m-0'>
            {/* begin::Form group */}
            <div className='text-center'>
              <label className='form-label fw-bolder text-dark fs-6 invisible'>To</label>
              <button
                type='submit'
                id='kt_sign_up_submit'
                className='btn btn-lg btn-primary w-100 mb-5 fs-8'
                style={{height: '35px'}}
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!loading && <span className='indicator-label'>Submit</span>}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
            {/* end::Form group */}
          </div>
        </div>
      </form>
    </div>
  )
}

export default WithdrawCommission
